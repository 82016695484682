import React from "react";
import { Form, Field } from "@progress/kendo-react-form";
import {
  ButtonSolid,
  mapping,
  NonValidationTextInput,
  NonValidationInput,
  DropDownListButton,
  RadioButtonGroup,
  MultiSelector,
  SearchAutoComplete,
  CustomizedCheckBox,
} from "@aim-mf/styleguide";

const riskCategoryList = [
  "Strategic",
  "Operational",
  "Financial",
  "Technology",
  "Compliance",
];

class NewRiskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      it_risk: false,
      non_it_risk: false,
      level: "parent",
      rskName: "",
      tierList: [1, 2, 3],
      newRiskTier: 1,
      categoryList: riskCategoryList,
      newRiskCategory: "Strategic",
      parentRikList: this.props.parentRiskList,
      parentRisk: {},
      riskDescription: "",
      owner: [],
      riskList: this.props.riskList,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.riskAutoPopulateDetail !== this.props.riskAutoPopulateDetail
    ) {
      let parentRisk = nextProps.riskAutoPopulateDetail.isParentRisk
        ? {}
        : this.props.riskList.filter((risk) => {
            return (
              parseInt(risk.sourceRiskID) ===
              nextProps.riskAutoPopulateDetail.parentRisk_id
            );
          })[0];

      let categoryList = nextProps.riskAutoPopulateDetail.isParentRisk
        ? riskCategoryList
        : [nextProps.riskAutoPopulateDetail.category];

      let tierList = nextProps.riskAutoPopulateDetail.isParentRisk
        ? [1, 2, 3]
        : [1, 2, 3].slice(
            this.props.riskList.filter((risk) => {
              return (
                parseInt(risk.sourceRiskID) ===
                nextProps.riskAutoPopulateDetail.parentRisk_id
              );
            })[0].tier - 1
          );

      let riskOwner = this.props.userList.filter((user) => {
        return nextProps.riskAutoPopulateDetail.owner.includes(user.id);
      });

      this.setState({
        level: nextProps.riskAutoPopulateDetail.isParentRisk ? "parent" : "sub",
        rskName: "",
        tierList: tierList,
        newRiskTier: nextProps.riskAutoPopulateDetail.tier,
        categoryList: categoryList,
        newRiskCategory: nextProps.riskAutoPopulateDetail.category,
        parentRikNameList: this.props.parentRiskList.map((a) => a.name),
        parentRisk: parentRisk,
        riskDescription: nextProps.riskAutoPopulateDetail.description,
        owner: riskOwner,
      });
    }
  }
  handleDescriptionTextInputChange = (value) => {
    this.setState({ riskDescription: value });
  };
  render() {
    const handleChange = (rows) => {
      this.setState({ rows: rows });
    };

    const handleSubmit = (dataItem) => {
      // construct submit package
      // console.log(dataItem);
      if (!(this.state.it_risk || this.state.non_it_risk)) {
        alert("please specify the risk type");
        return;
      }
      if (dataItem.number === "") {
        alert("Risk Code can not be empty");
        return;
      }
      if (dataItem.name === "") {
        alert("Risk name can not be empty");
        return;
      }
      if (dataItem.RiskDescription === "") {
        alert("Risk description can not be empty");
        return;
      }

      if (dataItem.level === "sub") {
        if (!dataItem.RiskParent) {
          dataItem.RiskParent = this.state.parentRikNameList[0];
        }
        if (!dataItem.category) {
          dataItem.category = this.state.riskList.filter((risk) => {
            return risk.name === dataItem.RiskParent;
          })[0].category;
        }
      } else {
        if (!dataItem.category) {
          dataItem.category = riskCategoryList[0];
        }
      }

      // console.log(dataItem)
      let parentRiskId;
      if (dataItem.level === "sub") {
        parentRiskId = dataItem.RiskParent.id
          ? dataItem.RiskParent.id
          : dataItem.RiskParent;
      }
      let riskCategoryId = riskCategoryList.findIndex((value) => {
        return value === dataItem.category;
      });
      // apply offset of the category ID
      riskCategoryId = riskCategoryId + 1;
      //console.log(dataItem.category)
      var dataPackage = {
        data: {
          it_risk: this.state.it_risk,
          non_it_risk: this.state.non_it_risk,
          number: dataItem.number,
          name: dataItem.name.trim(),
          description: dataItem.RiskDescription,
          category_id: riskCategoryId,
          tier: dataItem.tier,
          isParentRisk: dataItem.level === "parent",
          parentRisk_id: dataItem.level === "parent" ? null : parentRiskId,
          owner: dataItem.owner || [],
        },
      };
      //console.log(dataPackage);

      this.props.handleNewRiskSubmit(dataPackage);
      this.props.closeCreationForm();
    };

    const onTierChange = (event) => {
      this.setState({
        newRiskTier: event.target.value,
      });
    };

    const onITRiskTypeChange = (event) => {
      this.setState({
        it_risk: !this.state.it_risk,
      });
    };

    const onNonITRiskTypeChange = (event) => {
      this.setState({
        non_it_risk: !this.state.non_it_risk,
      });
    };

    const onCategoryChange = (event) => {
      this.setState({
        newRiskCategory: event.target.value,
      });
    };

    const onRadioGroupChange = (event) => {
      var riskLevel = event.value;
      //console.log(this.props.parentRiskList);
      if (riskLevel === "sub") {
        let parentRiskListExist = this.props.parentRiskList.length !== 0;
        // subrisk, need to update category and parentRiskname
        this.setState({
          parentRikNameList: this.props.parentRiskList.map((a) => a.name),
          parentRisk: this.props.parentRiskList[0],
          categoryList: parentRiskListExist
            ? [this.props.parentRiskList[0].category]
            : riskCategoryList,
          newRiskCategory: parentRiskListExist
            ? this.props.parentRiskList[0].category
            : riskCategoryList[0],
        });
      } else if (riskLevel === "parent") {
        this.setState({
          categoryList: riskCategoryList,
          newRiskCategory: riskCategoryList[0],
        });
      }

      this.setState({ level: riskLevel });
    };

    const onParentRiskChange = (event) => {
      //console.log(event)
      var parentRisk = event.value;
      //console.log([1, 2, 3].slice(parentRisk.tier - 1))
      this.setState({
        newRiskTier: parentRisk.tier,
        tierList: [1, 2, 3].slice(parentRisk.tier - 1),
        categoryList: [parentRisk.category],
        newRiskCategory: parentRisk.category,
      });
    };

    return (
      <div style={formBgStyle}>
        <div style={{ padding: "1rem" }}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              it_risk: false,
              non_it_risk: false,
              number: "",
              name: "",
              RiskDescription: "",
              tier: 1,
              RiskParent: this.state.parentRikList[0],
              level: "parent",
            }}
            render={(formRenderProps) => {
              return (
                <form onSubmit={formRenderProps.onSubmit}>
                  <fieldset style={{ border: "none" }}>
                    <div style={titleSpacingStyle}>
                      <legend style={mapping["heading/h5/lightleft"]}>
                        Create New Risk
                      </legend>
                    </div>
                    <Field
                      title={"Risk Level"}
                      data={radioGroupData}
                      name={"level"}
                      component={RadioButtonGroup}
                      CustomOnChange={onRadioGroupChange}
                      value={this.state.level}
                      populate_id={this.props.riskAutoPopulateDetail.id}
                    />
                    <div style={RadioButtonSpace} />
                    <div style={FieldSpacingStyle}>
                      <Field
                        title={"Risk Code"}
                        data={this.props.groupRiskList.map(({ id, name }) => ({
                          id,
                          name,
                        }))}
                        name={"number"}
                        filterKey={"number"}
                        component={NonValidationInput}
                        fieldstyle={Object.assign(
                          {},
                          mapping["forms/input-default"],
                          FieldTextAreaStyle
                        )}
                      />
                    </div>
                    <div style={FieldSpacingStyle}>
                      <Field
                        title={"Risk Name"}
                        data={this.props.groupRiskList.map(({ id, name }) => ({
                          id,
                          name,
                        }))}
                        name={"name"}
                        filterKey={"name"}
                        component={SearchAutoComplete}
                        handleSearchGroupRisk={this.props.handleSearchGroupRisk}
                        handleriskDetailRequest={
                          this.props.handleriskDetailRequest
                        }
                        searching={this.props.searching}
                      />
                    </div>

                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <Field
                          title={"Risk Tier"}
                          data={this.state.tierList}
                          name={"tier"}
                          component={DropDownListButton}
                          CustomOnChange={onTierChange}
                          DropDownButtonStyle={TierDropDownButtonStyle}
                          value={this.state.newRiskTier}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                        />
                      </div>
                      <div style={DropDownListSpace} />
                      <div>
                        <Field
                          title={"Risk Category"}
                          data={this.state.categoryList}
                          name={"category"}
                          component={DropDownListButton}
                          CustomOnChange={onCategoryChange}
                          DropDownButtonStyle={
                            this.state.level === "sub"
                              ? CategoryDropDownButtonDisableStyle
                              : CategoryDropDownButtonStyle
                          }
                          value={this.state.newRiskCategory}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                          disabled={this.state.level === "sub"}
                        />
                      </div>

                      <div style={DropDownListSpace} />
                      {this.state.level === "sub" && (
                        <div>
                          <Field
                            title={"Risk Parent"}
                            data={this.state.parentRikList}
                            dataDisplayKey={"name"}
                            name={"RiskParent"}
                            component={DropDownListButton}
                            CustomOnChange={onParentRiskChange}
                            DropDownButtonStyle={ParentRiskDropDownButtonStyle}
                            value={this.state.parentRisk}
                            populate_id={this.props.riskAutoPopulateDetail.id}
                          />
                        </div>
                      )}
                    </div>
                    <div style={{ height: "2rem" }} />
                    <div style={formTitleStyle}>Risk Type</div>
                    <div style={{ display: "inline-flex", color: "white" }}>
                      <div>
                        <Field
                          name={"it_risk"}
                          label={"IT Risk"}
                          component={CustomizedCheckBox}
                          onCheckChange={onITRiskTypeChange}
                          value={this.state.it_risk}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                        />
                      </div>
                      <div style={DropDownListSpace} />
                      <div>
                        <Field
                          name={"non_it_risk"}
                          label={"Non-IT Risk"}
                          component={CustomizedCheckBox}
                          onCheckChange={onNonITRiskTypeChange}
                          value={this.state.non_it_risk}
                          populate_id={this.props.riskAutoPopulateDetail.id}
                        />
                      </div>
                    </div>
                    <div style={{ height: "2rem" }} />
                    <div style={FieldSpacingStyle}>
                      <Field
                        title={"Risk Description"}
                        name={"RiskDescription"}
                        rows={5}
                        placeholder={"Enter your text here..."}
                        changerow={handleChange}
                        minrows={5}
                        maxrows={10}
                        lineheight={22}
                        component={NonValidationTextInput}
                        fieldstyle={Object.assign(
                          {},
                          mapping["forms/input-default"],
                          FieldTextAreaStyle
                        )}
                        value={this.state.riskDescription}
                        populate_id={this.props.riskAutoPopulateDetail.id}
                        CustomizedOnChange={
                          this.handleDescriptionTextInputChange
                        }
                      />
                    </div>
                    <Field
                      title={"Risk Owner"}
                      userList={this.props.userList}
                      value={this.state.owner}
                      populate_id={this.props.riskAutoPopulateDetail.id}
                      name={"owner"}
                      component={MultiSelector}
                    />
                  </fieldset>
                  <div style={ButtonStyle}>
                    <div>
                      <ButtonSolid
                        name="cancel"
                        height="2.5rem"
                        width="8rem"
                        color="Primary"
                        clickEvent={() => {
                          this.props.closeCreationForm();
                        }}
                      />
                    </div>
                    <div>
                      <ButtonSolid
                        name="create"
                        height="2.5rem"
                        width="8rem"
                        color="Primary"
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const fieldWidth = "32rem";

const formBgStyle = {
  height: "fit-content",
  width: "fit-content",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  display: "inline-flex",
  position: "relative",
};

const ButtonStyle = {
  display: "flex",
  width: fieldWidth,
  justifyContent: "space-between",
  paddingBottom: "2rem",
};

const FieldSpacingStyle = {
  paddingBottom: "2.1rem",
};

const titleSpacingStyle = {
  paddingBottom: "1.3rem",
  paddingTop: "1.3rem",
};

const FieldTextAreaStyle = {
  resize: "none",
  overflow: "hidden",
  height: "auto",
  width: fieldWidth,
  backgroundColor: mapping["Color_Field_BG_Lighter"],
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  color: mapping["Color_Basic_Light"],
  borderRadius: 0,
};

const radioGroupData = [
  { label: "Parent Risk", value: "parent", className: "task blue" },
  { label: "Sub Risk", value: "sub", className: "task blue" },
];

const RadioButtonSpace = {
  height: "2rem",
};

const TierDropDownButtonStyle = {
  width: "5rem",
  height: "2.5rem",
  backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
  border: "none",
  boxShadow: "none",
};

const CategoryDropDownButtonStyle = {
  width: "8.75rem",
  height: "2.5rem",
  backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
  border: "none",
  boxShadow: "none",
};

const CategoryDropDownButtonDisableStyle = {
  width: "8.75rem",
  height: "2.5rem",
  backgroundColor: mapping["Color_DropDownButton_BG_Lighter_Disable"],
  border: "none",
  boxShadow: "none",
};

const ParentRiskDropDownButtonStyle = {
  width: "13rem",
  height: "2.5rem",
  backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
  border: "none",
  boxShadow: "none",
};

const DropDownListSpace = {
  width: "1.3rem",
};

const formTitleStyle = {
  ...mapping["forms/label/1-default"],
  ...mapping["Form_Label_Padding"],
  display: "flex",
  paddingBottom: "9px",
  color: mapping["Color_Basic_Light"],
};
export { NewRiskForm };
