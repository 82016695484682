import React from "react";
import {
  GridColumnMenuCheckboxFilter,
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  IconButtonSolid,
  ButtonSolid,
  SearchBar,
  Pill,
  TooltipTemplate,
  FormatDate,
  IconSVG,
} from "@aim-mf/styleguide";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { navigateToUrl } from "single-spa";

class ColumnMenuCheckboxFilter extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...this.props}
          data={this.props.sort[0].data}
          expanded={true}
        />
      </div>
    );
  }
}

class RiskTable extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    this.state = {
      sort: [{ field: "formatDateTime", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "createdDatetime", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  selectionChange = (event) => {
    const result = this.state.result.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    this.setState({ result });
  };
  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const result = this.state.result.map((item) => {
      item.selected = checked;
      return item;
    });
    this.setState({ result });
  };

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the risks that suits the search keywords (risk name and risk owner)
    let riskOnSearch = this.state.filterData.filter((riskItem) => {
      let matchOwner = false;
      let matchRiskName = false;
      if (riskItem.owner.length > 0) {
        //console.log("riskItem has a property riskOwner");
        riskItem.owner.forEach((owner) => {
          matchOwner =
            matchOwner ||
            owner.toLowerCase().includes(searchContent.toLowerCase());
        });
      }
      if (riskItem.name) {
        matchRiskName = riskItem.name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      return matchRiskName || matchOwner;
    });
    // if match subrisk, can find its parentrisk information
    let parentRiskList = riskOnSearch
      .map((a) => a.parentRisk_id)
      .filter((item) => {
        return item != null;
      });
    //console.log(parentRiskList);
    var riskIDList = riskOnSearch.map((a) => a.id);
    parentRiskList.forEach((ID) => {
      if (!riskIDList.includes(ID)) {
        var newParentRisk = this.state.filterData.filter((propsRiskItem) => {
          return propsRiskItem.id == ID;
        });
        if (newParentRisk[0]) {
          riskOnSearch.push(newParentRisk[0]);
        }
      }
    });
    this.setState({ searchData: riskOnSearch, result: riskOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    var datastate = event.data;
    datastate.take = 100;
    this.setState(this.createDataState(datastate));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];

    let sortedData = orderBy(data, sort);

    while (sortedData.length !== 0) {
      //console.log(sortedData);
      if (sortedData[0].isParentRisk) {
        let parentID = sortedData[0].sourceRiskID;
        result = result.concat(sortedData[0]);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id == parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id != parentID;
        });
      } else {
        let parentID = sortedData[0].parentRisk_id;
        let parentRiskSort = sortedData.filter((risk) => {
          return risk.sourceRiskID == parentID;
        });
        result = result.concat(parentRiskSort);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id === parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id !== parentID;
        });
      }

      //console.log(sortedSubRisk);
      //console.log(result);
    }

    //console.log("sort function");

    //console.log(result);

    return result;
  };

  //archive function
  archiveRisk = () => {
    // get all risk with selected
    let selectedRisk = this.state.result.filter((risk) => {
      return risk.selected;
    });
    let archiveRiskID = selectedRisk.map((a) => a.id);
    this.props.archive(archiveRiskID);
  };
  render() {
    let tooltip;
    //console.log(this.state.result);

    // reformat result cater for the display requirement
    let displayResult = this.state.result;
    displayResult = displayResult.map((a) => {
      let type = a.isParentRisk ? "Parent Risk" : "Sub Risk";
      let formatDateTime = FormatDate(a.createdDatetime, "DD/MM/YYYY HH:mm:ss");
      return { ...a, type: type, formatDateTime: formatDateTime };
    });

    return (
      <div>
        <div
          id="table-tool-bar"
          style={{
            marginRight: "1rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
          className="mb-1"
        >
          <div className="mx-1">
            <SearchBar
              searchCallBack={this.onSearchCallBack}
              hint={"Search by risk name or risk owner..."}
            />
          </div>
          {this.props.rights.Delete === 1 && (
            <div className="mx-1">
              <IconButtonSolid
                height={"2rem"}
                width={"6rem"}
                color={"Danger"}
                name={"Archive"}
                iconPos={"Left"}
                icon={
                  <IconSVG name={"archive"} size={"0.8"} color={"#ffffff"} />
                }
                clickEvent={this.archiveRisk}
              />
            </div>
          )}
          {this.props.rights.create === 1 && (
            <div className="mx-1">
              <IconButtonSolid
                height={"2rem"}
                width={"9rem"}
                iconPos={"Left"}
                icon={
                  <IconSVG name={"newRisk"} size={"0.8"} color={"#ffffff"} />
                }
                name={"Add New Risk"}
                clickEvent={this.props.addNew}
              />
            </div>
          )}
        </div>

        <Grid
          style={{ maxHeight: "500px", height: "fit-content" }}
          {...this.state.dataState}
          data={this.customOrderBy(displayResult, this.state.sort)}
          sortable
          onDataStateChange={this.dataStateChange}
          selectedField="selected"
          onSelectionChange={this.selectionChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onRowClick={this.rowClick}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
          customizedProps={"123"}
        >
          <Column
            field="selected"
            width="50px"
            headerSelectionValue={
              this.state.result.findIndex(
                (dataItem) => dataItem.selected === false
              ) === -1
            }
          />
          <Column
            field="riskNumber"
            title="ID"
            width="100px"
            cell={(props) => {
              return (
                <td className="table-risk-id">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    className="table-risk-id div_hover"
                    onClick={() => {
                      navigateToUrl("risk-edit/" + props.dataItem.id + "/");
                    }}
                  >
                    {props.dataItem["parentRisk_id"] ? "⤷" : " "}
                    {props.dataItem[props.field]}
                  </div>
                </td>
              );
            }}
          />
          <Column
            field="name"
            title="Name"
            width="250px"
            cell={(props) => {
              return (
                <td>
                  {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                  <div
                    onMouseOver={(event) =>
                      tooltip && tooltip.handleMouseOver(event)
                    }
                    onMouseOut={(event) =>
                      tooltip && tooltip.handleMouseOut(event)
                    }
                  >
                    <span title={props.dataItem.description}>
                      {props.dataItem[props.field]}
                    </span>
                    <Tooltip
                      content={(props) => (
                        <TooltipTemplate
                          tooltipStyle={{ maxWidth: "15rem" }}
                          content={props.title}
                        />
                      )}
                      ref={(el) => (tooltip = el)}
                      anchorElement="target"
                      position="auto"
                      openDelay={300}
                    />
                  </div>
                </td>
              );
            }}
          />
          <Column
            field="category"
            title="Category"
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <Column field="type" title="Type" />
          <Column
            field="status"
            title="Status"
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <Column
            field="tier"
            title="Tier"
            width={"90px"}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <Column field="formatDateTime" title="Date Created" />
          <Column
            field="owner"
            title="Risk Owner"
            width="200px"
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    {props.dataItem[props.field].map((owner) => {
                      return (
                        <Pill
                          pillStyle={{
                            display: "inline-flex",
                            marginRight: "0.3rem",
                          }}
                          content={owner}
                          color={"rgba(124,199,255,0.2)"}
                        />
                      );
                    })}
                  </td>
                );
              } else {
                return <td />;
              }
            }}
          />
        </Grid>
      </div>
    );
  }

  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.isParentRisk ? parentRow : subRiskRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

export { RiskTable };
