import React, { useCallback } from "react";
import { RiskManagement } from "./page/riskManagement";
import {
  fetchAPIWithPermission,
  fetchAdminAPIWithPermission,
} from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";

const initialState = {
  loadingRisk: true,
  loadingUser: true,
  loadingCurrentUser: true,
  loadingJWT: true,
  searching: false,
  riskList: [],
  riskListFromGroup: [],
  riskAutoPopulateDetail: {},
  userList: [],
  searchSubscription: {},
  currentUser: {},
  JWT: {},
  role: "",
  rights: {},
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const {
    loadingRisk,
    loadingUser,
    loadingCurrentUser,
    loadingJWT,
    searching,
    riskList,
    riskListFromGroup,
    riskAutoPopulateDetail,
    userList,
    searchSubscription,
    currentUser,
    JWT,
    role,
    rights,
  } = state;

  const handleSubmitForm = React.useCallback((data) => {
    const subscription = fetchAPIWithPermission("risks", data).subscribe(
      (results) => {
        dispatch({ type: "loadingRisk" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleSubmitForm(data);
        });
      }
    );

    return () => subscription.unsubscribe();
  });

  const handleArchiveRisk = React.useCallback((idList) => {
    idList.forEach((id) => {
      fetchAPIWithPermission("archiverisk/" + id);
    });
    dispatch({ type: "loadingRisk" });
  });

  const handleSearchGroupRisk = React.useCallback((searchContent) => {
    dispatch({ type: "searching" });
    // dispose unwanted subscription
    if (Object.keys(searchSubscription).length !== 0) {
      searchSubscription.unsubscribe();
    }

    let requestURL =
      "risksearch?search_str=" + encodeURIComponent(searchContent);
    //console.log(requestURL);
    let param = { data: { isArchived: "NON-ARCHIVED" } };

    const subscription = fetchAPIWithPermission(requestURL, param).subscribe(
      (results) => {
        //console.log("in post api results");
        dispatch({ type: "loadGroupRisk", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );

    dispatch({ type: "storeSearchSubscription", subscription });

    return () => subscription.unsubscribe();
  });

  const handleRiskDetailRequest = React.useCallback((riskID) => {
    //console.log("in app data get risk detail");
    //console.log(riskID);
    dispatch({ type: "populateRiskDetail", riskID });
    // const subscription = fetchAPI("getrisks").subscribe(
    //   (results) => {
    //     //console.log("in post api results");
    //     dispatch({ type: "loadRiskDetail", results, riskID });
    //   },
    //   (err) => {
    //       console.log("err", err); // eslint-disable-line
    //   }
    // );
    //
    // return () => subscription.unsubscribe();
  });

  // const handleUserListRequest = React.useCallback(() => {
  //   const subscription = fetchUserAPIWithPermission("user").subscribe(
  //     (results) => {
  //       dispatch({ type: "loadUser", results });
  //     },
  //     (err) => {
  //         console.log("err", err); // eslint-disable-line
  //     }
  //   );
  //
  //   return () => subscription.unsubscribe();
  // });

  const getRiskList = React.useCallback(
    (archiveParam) => {
      if (loadingRisk) {
        dispatch({ type: "loadingRisk" });
        const subscription = fetchAPIWithPermission("getrisks", {
          data: archiveParam,
        }).subscribe(
          (results) => {
            dispatch({ type: "newRisk", results });
          },
          (err) => {
              console.log("err", err); // eslint-disable-line
          }
        );

        return () => subscription.unsubscribe();
      }
    },
    [loadingRisk]
  );

  React.useEffect(() => {
    dispatch({
      type: "loadCurrentUser",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadUserFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingRisk) {
      let param = { data: { isArchived: "NON-ARCHIVED" } };
      dispatch({ type: "loadingRisk" });
      const subscription = fetchAPIWithPermission("getrisks", param).subscribe(
        (results) => {
          let newResults = { results: results, userList: userList };
          dispatch({ type: "newRisk", newResults });
        },
        (err) => {
                console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadRiskFunction();
          });
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingJWT) {
      dispatch({ type: "loadingJWT" });
      const subscription = fetchAPIWithPermission("decodetoken").subscribe(
        (results) => {
          dispatch({ type: "loadJWT", data: results.data });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadJWTFunction();
          });
        }
      );

      return () => subscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingRisk,
    userList,
    loadingJWT,
    loadUserFunction,
    loadRiskFunction,
    loadJWTFunction,
  ]);

  const loadJWTFunction = useCallback(() => {
    const subscription = fetchAPIWithPermission("decodetoken").subscribe(
      (results) => {
        dispatch({ type: "loadJWT", data: results.data });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadJWTFunction();
        });
      }
    );
    return () => subscription.unsubscribe();
  });

  const loadUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadUserFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });
  const loadRiskFunction = useCallback(() => {
    let param = { data: { isArchived: "NON-ARCHIVED" } };
    dispatch({ type: "loadingRisk" });
    const subscription = fetchAPIWithPermission("getrisks", param).subscribe(
      (results) => {
        let newResults = { results: results, userList: userList };
        dispatch({ type: "newRisk", newResults });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadRiskFunction();
        });
      }
    );

    return () => subscription.unsubscribe();
  });

  return (
    <div>
      {/*<RoleSwitchPanel getRights={getRights} role={role} rights={rights} />*/}
      <RiskManagement
        riskList={riskList}
        groupRiskList={riskListFromGroup}
        populateRiskDetail={riskAutoPopulateDetail}
        userList={userList}
        handleNewRiskSubmit={handleSubmitForm}
        handleArchiveRisk={handleArchiveRisk}
        handleSearchGroupRisk={handleSearchGroupRisk}
        handleriskDetailRequest={handleRiskDetailRequest}
        riskListFromGroup={riskListFromGroup}
        riskAutoPopulateDetail={riskAutoPopulateDetail}
        // handleUserListRequest={handleUserListRequest}
        searching={searching}
        currentUser={currentUser}
        rights={rights}
      />
    </div>
  );
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "loadingRisk":
      return { ...state, loadingRisk: true };
    case "loadingUser":
      // console.log("in loading riskList")
      return { ...state, loadingUser: true };
    case "loadingJWT":
      // console.log("in loading riskList")
      return { ...state, loadingJWT: true };
    case "loadingCurrentUser":
      // console.log("in loading riskList")
      return { ...state, loadingCurrentUser: true };
    case "newRisk":
      let riskList = action.newResults.results.data;
      riskList.forEach((risk, riskIndex) => {
        risk.owner.forEach((username, ownerIndex) => {
          let display_name = action.newResults.userList.filter((user) => {
            return user.username === username;
          })[0].display_name;
          riskList[riskIndex]["owner"][ownerIndex] = display_name;
        });
      });
      return {
        ...state,
        riskList: riskList,
        loadingRisk: false,
      };

    case "loadGroupRisk":
      let tailingSpaceGroupRisk = action.results.data;
      tailingSpaceGroupRisk.forEach((risk) => {
        risk.name = risk.name + "\u00A0";
      });
      return {
        ...state,
        //riskList: state.riskList.concat(action.results.data),
        riskListFromGroup: tailingSpaceGroupRisk,
        //nextPage: false,
        loadingRisk: false,
        searching: false,
      };
    case "populateRiskDetail":
      return {
        ...state,
        //riskList: state.riskList.concat(action.results.data),
        riskAutoPopulateDetail: state.riskListFromGroup.filter((risk) => {
          return risk.id === action.riskID;
        })[0],
        //nextPage: false,
        loadingRisk: false,
      };
    case "loadUser":
      // console.log(action.results.data);
      return {
        ...state,
        userList: action.results.data,
        loadingUser: false,
      };

    case "loadCurrentUser":
      // console.log(action.results.data);
      return {
        ...state,
        currentUser: action.data,
        loadingCurrentUser: false,
      };
    case "loadJWT":
      let rightsInt = action.data.acl["00000000-0000-0000-0000-000000000000"];
      let rights = {
        create: (rightsInt >> 4) & 1,
        view: (rightsInt >> 3) & 1,
        Edit: (rightsInt >> 2) & 1,
        Delete: (rightsInt >> 1) & 1,
        Approve: rightsInt & 1,
      };
      return {
        ...state,
        JWT: action.data,
        rights: rights,
        loadingJWT: false,
      };
    case "storeSearchSubscription":
      return {
        ...state,
        searchSubscription: action.subscription,
      };
    case "searching":
      return {
        ...state,
        searching: true,
      };
    case "fetchRights":
      return {
        ...state,
        rights: action.data.rights,
        role: action.data.role,
      };
    default:
      throw Error(`Unknown action type '${action.type}'`);
  }
}

export default App;
